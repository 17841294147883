const App__modal = {
  modals: [],
  intents: ["onload", "onexit", "onclick"],
  mode: "prod",
  modalCTAButtons: [],

  init: function () {
    try {
      this.modals = document.querySelectorAll("[data-hook=modalCTA]");

      this.listenToModalIntents();

      this.modalCTAButtons = document.querySelectorAll(
        "[data-hook=modalCTAButton]"
      );

      this.listenToScroll();
    } catch (e) {
      console.error(e);
    }
  },

  /**
   *
   * LISTENERS
   *
   */
  listenToModalIntents: function () {
    if (this.modals === undefined || this.modals.length === 0) return;

    this.modals.forEach((modal) => {
      if (this.hasCookie(modal)) return;

      switch (modal.dataset.intent) {
        case "onexit":
          document.onmouseout = function (e) {
            App__modal.handleExitIntent(modal, e);
          };
          break;

        case "onload":
          this.handleShowModal(modal);
          break;

        case "onclick":
          this.handleClickModal(modal);
          break;

        default:
          // Nothing happens.
          break;
      }
    });
  },

  listenToScroll: function () {
    if (this.modalCTAButtons === undefined || this.modalCTAButtons.length === 0)
      return;

    window.addEventListener("scroll", (e) => {
      this.handleStickyCTAS(e, 600);
    });
  },

  /**
   *
   * HANDLERS
   *
   */
  handleExitIntent: function (modal, e) {
    if (!e.toElement && !e.relatedTarget && e.clientY < 5) {
      this.handleShowModal(modal);
    }
  },

  handleShowModal: function (modal) {
    if (modal.dataset.shown || this.hasCookie(modal)) return;

    const modalInstance = new App.bootstrap.Modal(modal);
    modalInstance.show();

    this.setCookies(modal);
  },

  handleStickyCTAS: function (e, distance) {
    if (window.pageYOffset > distance) {
      this.modalCTAButtons.forEach((item) =>
        item.setAttribute("aria-hidden", true)
      );
    } else {
      this.modalCTAButtons.forEach((item) =>
        item.setAttribute("aria-hidden", false)
      );
    }
  },

  handleClickModal: function (e) {
    // Nothing needed atm, setup for future use.
  },

  /**
   *
   * COOKIES
   *
   */
  setCookies: function (modal) {
    App.Cookies.set(modal.id, true, {
      expires: Number(modal.dataset.expires) ?? 30,
    });

    // JIC they have cookies disabled, atleast don't show it in the same page session.
    modal.setAttribute("data-shown", true);
  },

  hasCookie: function (modal) {
    if (this.mode === "dev" || Number(modal.dataset.expires) === 0) return;

    const cookie = App.Cookies.get(modal.id);
    if (cookie === undefined) return;

    return Boolean(cookie);
  },
};

export default App__modal;
