const App__alphasort = {
  element: null,
  resultsWrapper: null,

  triggers: [],
  results: [],

  init: function () {
    try {
      const alphasorts = Array.from(document.querySelectorAll(".alphasort"));

      if (!alphasorts.length) return;

      this.triggers = Array.from(
        document.querySelectorAll("[data-hook=alphasort__trigger]")
      );

      this.listenToTriggers();
    } catch (e) {
      console.error(e);
    }
  },

  listenToTriggers: function () {
    if (!this.triggers.length) return;

    this.triggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        if (e.target.dataset.sort === undefined) return;

        this.element = e.target.closest(".alphasort");

        if (this.element === undefined || this.element === null) return;

        this.results = Array.from(
          this.element.querySelectorAll("[data-hook=alphasort__section]")
        );

        this.resultsWrapper = this.element.querySelector(".alphasort__results");

        if (
          !this.results ||
          this.resultsWrapper === undefined ||
          this.resultsWrapper === null
        )
          return;

        this.setResultColumns();
        this.setResultSections(e.target);
        this.setTriggers(e.target);
        this.scrollToSection(e.target);
      });
    });
  },

  setResultSections: function (target) {
    const sortBy = target.dataset.sort;

    this.results.forEach((section) => {
      if (sortBy === "all") {
        section.classList.remove("d-none");
        section.setAttribute("aria-hidden", false);

        this.element.classList.remove("is-filtered");
      } else if (section.dataset.sortSection === sortBy) {
        section.setAttribute("aria-hidden", false);
        section.classList.remove("d-none");

        this.element.classList.add("is-filtered");
      } else {
        section.setAttribute("aria-hidden", true);
        section.classList.add("d-none");
      }
    });
  },

  setTriggers: function (target) {
    const sortBy = target.dataset.sort;
    const triggers = Array.from(
      this.element.querySelectorAll("[data-hook=alphasort__trigger]")
    );

    if (!triggers.length) return;

    triggers.forEach((trigger) => {
      trigger.classList.remove("is-active");

      if (sortBy === "all") {
        trigger.setAttribute("aria-expanded", true);
      } else {
        trigger.setAttribute("aria-expanded", false);
      }
    });

    target.classList.add("is-active");
    target.setAttribute("aria-expanded", true);

    return;
  },

  scrollToSection: function (target) {
    if (target.dataset.sort === "all") {
      return;
    }

    this.resultsWrapper.scrollIntoView({
      behavior: "smooth",
    });
  },

  setResultColumns: function () {
    const numResults = this.results.length;
    let columns = 1;

    if (numResults === 2) {
      columns = 2;
    }

    if (numResults === 3) {
      columns = 3;
    }

    if (numResults > 3) {
      columns = 4;
    }

    this.resultsWrapper.classList.add(`columns-${columns}`);
  },
};

export default App__alphasort;
