const App__physicianDetail = {
  cta: undefined,
  init: function () {
    try {
      this.cta = document.querySelector("[data-hook=physicianDetailCTA]");

      if (this.cta === undefined || this.cta === null) return;

      this.handleCTAVisibility();
    } catch (e) {
      console.error(e);
    }
  },

  handleCTAVisibility: function () {
    const columns = this.cta.querySelectorAll(".col");

    if (columns.length > 0) return;

    this.cta.remove();
  },
};

export default App__physicianDetail;
