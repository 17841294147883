const App__UUID = {
  generate: function (prefix = null, suffix = null) {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    if (prefix !== null) {
      uuid = `${prefix}-${uuid}`;
    }

    if (suffix !== null) {
      uuid = `${uuid}-${suffix}`;
    }

    return uuid;
  },

  /**
   * Generate an array of random unique numbers
   *
   * Helpful when trying to grab random indexes from a list of elements.
   *
   * Usage:
   * const list = Array.from(document.querySelectorAll('ul li'));
   * const randomIndexes = App.utils.uuid.randomArrayOfNumbersBetween(0, list.length, 5);
   * const randomList = list.filter((item, i) => randomIndexes.includes(i));
   *
   * @param {number} min Minimum range
   * @param {number} max Maximum range
   * @param {number} qty The number of results in the array
   * @param {number} maxIterations JIC the loop has a hard time finding a number, this will cap the amount of tries.
   * @returns array<number>
   */
  randomArrayOfNumbersBetween: function (
    min = 0,
    max = 100,
    qty = 4,
    maxIterations = 10
  ) {
    if (min === max) {
      console.error("Min and Max must be unique");
      return;
    }

    let output = [];

    for (let i = 0; i < qty; i++) {
      output.push(
        this.randomUniqueNumberRecursive(
          output,
          min,
          max,
          0,
          maxIterations,
          qty
        )
      );
    }

    return output;
  },

  /**
   * Get a unique number exluding a list of numbers.
   *
   * Will return 0 if the max iteration is hit.
   *
   * @param {number | number[]} input The number or array of numbers that already exist.
   * @param {number} min Minimum range
   * @param {number} max Maximum range
   * @param {number} iteration Current iteration of recursion
   * @param {number} maxIterations JIC the loop has a hard time finding a number, this will cap the amount of tries.
   * @returns number
   */
  randomUniqueNumberRecursive: function (
    input,
    min,
    max,
    iteration = 0,
    maxIterations = 10
  ) {
    
    if (iteration === maxIterations || input.length === max) {
      return;
    }

    const randomNumber = this.randomNumberBetween(min, max);

    if (input !== randomNumber && !input.includes(randomNumber)) {

      return Number(randomNumber);
    }

    return this.randomUniqueNumberRecursive(input, min, max, iteration + 1);
  },

  /**
   * Get a random number between two other numbers.
   *
   * @param {number} min
   * @param {number} max
   * @returns number
   */
  randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },
};

export default App__UUID;
