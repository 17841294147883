const App__pressRelease = {
  location: undefined,

  init: function () {
    try {
      this.location = document.querySelector(
        "[data-hook=pressRelease__location]"
      );

      if (this.location === undefined || this.location === null) return;

      this.handleLocation();
    } catch (e) {
      console.error(e);
    }
  },

  getFirstParagaph: function (element) {
    let content = Array.from(App.utils.sibling.getAll(element));

    if (content.length === 0) return;

    content = content.filter(
      (item) => item.nodeName === "P" && !item.classList.contains("like-h3")
    );

    if (content.length === 0) return;

    return content[0];
  },

  handleLocation: function () {
    const paragraph = this.getFirstParagaph(this.location);

    if (paragraph === undefined || !paragraph) return;

    paragraph.prepend(this.location);
  },
};

export default App__pressRelease;
