const App__formPriceDisplay = {
  debug: true,

  fields: [],
  totalElement: null,
  totalValue: 0,
  baseElement: null,
  baseValue: 0,

  init: function () {
    try {
      this.baseElement = document.querySelector("[data-hook=form-price-base]");
      this.totalElement = document.querySelector(
        "[data-hook=form-price-total]"
      );
      this.fields = Array.from(
        document.querySelectorAll("[data-hook=form-price-field]")
      );

      if (
        this.totalElement === undefined ||
        this.totalElement === null ||
        this.fields.length === 0
      )
        return;

      this.totalValue = parseFloat(this.totalElement.innerText);

      if (this.debug)
        console.log({
          totalElement: this.totalElement,
          fields: this.fields,
          totalValue: this.totalValue,
          baseValue: this.baseValue,
        });

      this.listenToFields();

      this.maybeSetBaseValue();

      this.handleUpdateTotal();
    } catch (e) {
      console.error(e);
    }
  },

  listenToFields: function () {
    this.fields.forEach((field) => {
      field.addEventListener("change", function (e) {
        if (this.debug) console.log({ e });

        App.formPriceDisplay.handleUpdateTotal();
      });
    });
  },

  handleUpdateTotal: function () {
    let newTotal = 0.0;
    newTotal = newTotal + this.baseValue;

    if (this.debug) console.log({ newTotal });

    this.fields.forEach((field) => {
      if (field.checked) {
        const fieldValue = parseFloat(field.dataset.formPrice);
        newTotal = newTotal + fieldValue;
        if (this.debug) console.log({ fieldValue, newTotal });
      }
    });

    this.totalValue = newTotal;

    this.totalElement.innerText = this.totalValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
  },

  maybeSetBaseValue: function () {
    if (this.baseElement === undefined || this.baseElement === null) return;

    this.baseValue = parseFloat(this.baseElement.innerText);

    if (this.debug)
      console.log({ baseElement: this.baseElement, baseValue: this.baseValue });
  },
};

export default App__formPriceDisplay;
