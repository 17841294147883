const App__linkClasses = {
  init: function () {
    this.correctAnchorSpanButtons();
    this.correctSpanAnchorButtons();

    this.addExternalIcon();
  },
  addExternalIcon: function () {
    const internalUrls = [
      // Same Page / Query vars
      '[href^="#"]',
      '[href^="?"]',

      // Relative
      '[href^="/"]',
      '[href^="./"]',

      // Device triggers
      '[href^="mailto"]',
      '[href^="tel"]',

      // Dev/Prod environments
      '[href*="nationaljewish.org"]',
      '[href*="njhealth.org"]',
      '[href*="njhmvc"]',
      '[href*="localhost"]',

      // Manual override
      ".hide-external-icon",
    ];

    // The selectors we want to target.
    const selectors = [".app-main a", ".app-header__utility-nav a"];

    // Add any "non-automated" selectors here.
    let querySelector = [];

    // Loop over the selectors and apply a not to the interal urls.
    selectors.map((selector) => {
      querySelector.push(`${selector}:not(${internalUrls.join(",")})`);
    });

    document.querySelectorAll(querySelector.join(",")).forEach(function (item) {
      if (
        item.hasAttribute("href") &&
        item.getAttribute("href") !== null &&
        item.getAttribute("href") !== ""
      ) {
        item.classList.add("is-external-link");
        item.setAttribute("target", "_blank");
        item.insertAdjacentHTML(
          "beforeend",
          '<span class="visually-hidden"> (Opens in a new window)</span>'
        );
      }
    });
  },

  correctAnchorSpanButtons: function (target = ".app-main a > span.btn") {
    // Get only links that have span.btn as direct children.
    const buttons = document.querySelectorAll(target);

    if (!buttons || buttons.length === 0) return;

    // Loop over the spans.
    buttons.forEach(function (span) {
      const anchor = span.parentElement;

      // Bail if there are no classes
      if (!span.classList.length) return;

      // Add all classes from the span to the anchor.
      span.classList.forEach((spanClass) => {
        anchor.classList.add(spanClass);
      });

      // Replace the span with just the text of the button.
      anchor.innerHTML = span.textContent;
    });
  },

  correctSpanAnchorButtons: function (target = ".app-main span.btn > a") {
    // Get only links that have span.btn as direct parents of anchors.
    const buttons = document.querySelectorAll(target);

    if (!buttons || buttons.length === 0) return;

    // Loop over the anchors.
    buttons.forEach(function (anchor) {
      const span = anchor.parentElement;

      // Bail if there are no classes
      if (!span.classList.length) return;

      // Add all classes from the span to the anchor.
      span.classList.forEach((spanClass) => {
        anchor.classList.add(spanClass);
      });

      // Insert the anchor before the span
      span.parentNode.insertBefore(anchor, span);

      // Delete the span from the DOM
      span.remove();
    });
  },
};

export default App__linkClasses;
