/**
 * This class is specifically used to handle the conditional logic on the payment form.
 * Njh.Mvc\Views\Shared\Components\Payment\_PayMyBill.cshtml
 * https://localhost:44326/subpayment
 */
const App__formConditionalLogic = {
  debug: false,

  form: null,

  init: function () {
    try {
      this.form = document.querySelector("[data-hook=hasConditionalLogic]");

      if (this.form === undefined || this.form === null) return;

      this.setDefaultFormState();

      this.listenToChanges();
    } catch (e) {
      console.error(e);
    }
  },

  listenToChanges: function () {
    this.form.addEventListener("change", function (e) {
      if (this.debug) console.log({ e });
      const field = e.srcElement;

      App__formConditionalLogic.setFieldState(field);
    });
  },

  setFieldState: function (field) {
    const show = field.dataset.show;
    const hide = field.dataset.hide;
    const toggle = field.dataset.toggle;

    if (this.debug)
      console.log({ field, show, hide, toggle, checked: field.checked });

    if (show === undefined && hide === undefined && toggle === undefined)
      return;

    if (show && field.checked) {
      const element = document.querySelector(show);
      this.handleShow(element);
    }

    if (hide && field.checked) {
      const element = document.querySelector(hide);
      this.handleHide(element);
    }

    if (toggle) {
      this.handleToggle(field, toggle);
    }
  },

  setDefaultFormState: function () {
    const fields = Array.from(
      this.form.querySelectorAll("[data-show], [data-hide], [data-toggle]")
    );

    if (fields.length === 0) return;

    fields.forEach((field) => {
      this.setFieldState(field);
    });
  },

  handleShow: function (element) {
    if (element === undefined) return;

    element.hidden = false;

    const children = this.getChildrenFields(element);

    if (!children) return;

    children.forEach((child) => {
      child.disabled = false;
    });
  },

  handleHide: function (element) {
    if (element === undefined) return;

    element.hidden = true;

    const children = this.getChildrenFields(element);

    if (!children) return;

    children.forEach((child) => {
      child.disabled = true;
    });
  },
  /**
   * This handles the checkbox for billing address same as organization information
   * @param {HTMLInputElement} changedField the field that triggered the change.
   * @param {string} toggle ID of the section we're toggling.
   */
  handleToggle: function (changedField, toggle) {
    const element = document.querySelector(toggle);

    if (changedField.checked) {
      this.handleHide(element);
      return;
    }

    this.handleShow(element);
  },

  getChildrenFields: function (element) {
    const children = element.querySelectorAll("input, select, textarea");

    if (children === undefined || children.length === 0) {
      console.error("No children found");
      return;
    }

    return children;
  },
};

export default App__formConditionalLogic;
