const App__microsite = {
  header: null,
  mobileTrigger: null,
  mainNav: null,
  utilityNav: null,
  aside: null,
  skipContent: null,

  init: function () {
    try {
      this.header = document.querySelector("[data-hook=micrositeHeader");

      if (this.header === undefined || this.header === null) return;

      this.mobileTrigger = document.querySelector(
        "[data-hook=micrositeHeader__mobileTrigger"
      );

      this.mainNav = document.querySelector("[data-hook=microsite__mainNav]");

      if (
        this.mobileTrigger === undefined ||
        this.mobileTrigger === null ||
        this.mainNav === undefined ||
        this.mainNav === null
      )
        return;

      this.utilityNav = this.mainNav.querySelector(
        "[data-hook=micrositeHeader__utilityNav]"
      );

      this.aside = this.mainNav.querySelector("aside");
      this.skipContent = this.mainNav.querySelector(".subpage__aside-nav-skip");

      this.listenToTrigger();

      this.maybeRemoveSkipContent();
    } catch (e) {
      console.error(e);
    }
  },

  /***
   *
   * LISTENER METHODS
   *
   */
  listenToTrigger: function () {
    this.mobileTrigger.addEventListener("click", function (e) {
      App__microsite.handleToggleNavs();
    });
  },

  /***
   *
   * HANDLER METHODS
   *
   */

  handleClose: function () {
    this.mobileTrigger.setAttribute("aria-expanded", false);

    this.mainNav.style.display = "none";
    this.mainNav.setAttribute("aria-hidden", true);

    if (this.utilityNav !== undefined && this.utilityNav !== null) {
      this.utilityNav.style.display = "none";
      this.utilityNav.setAttribute("aria-hidden", true);
    }
  },

  handleOpen: function () {
    this.mobileTrigger.setAttribute("aria-expanded", true);

    this.mainNav.style.display = "block";
    this.mainNav.setAttribute("aria-hidden", false);

    if (this.utilityNav !== undefined && this.utilityNav !== null) {
      this.utilityNav.style.display = "block";
      this.utilityNav.setAttribute("aria-hidden", false);
    }

    if (this.aside !== undefined && this.aside !== null) {
      this.aside.classList.add("d-block");
    }
  },

  handleToggleNavs: function () {
    if (this.mainNav.style.display === "block") {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  },

  maybeRemoveSkipContent: function () {
    // Maybe Remove the skipContent element from the mobile navigation.
    if (this.skipContent !== undefined && this.skipContent !== null) {
      this.skipContent.remove();
    }
  },
};

export default App__microsite;
