import "../styles/app.scss";

let App = (window.App = {});

/*
 * Other 3rd Party Dependencies
 * =========================================================================== */
// import accessibleAutocomplete from 'accessible-autocomplete';
import * as bootstrap from "bootstrap";
import * as focusTrap from "focus-trap";
import Swiper from 'swiper/bundle';
import DOMPurify from 'dompurify'
import Cookies from 'js-cookie';

App.bootstrap = bootstrap;
App.Swiper = Swiper;
App.Purify = DOMPurify;
App.Cookies = Cookies;

/**
 * Custom Dependencies
 * =========================================================================== */
// Components
import App__appHeader from "./components/app-header";
import App__accordion from './components/accordion';
import App__alphasort from './components/alphasort';
import App__events from './components/events';
// import App__forms from './components/forms'; // DEPRECATED
import App__formConditionalLogic from './components/form-conditional-logic';
import App__formPriceDisplay from "./components/form-price-display";
import App__formSubmitKentico from "./components/form-submit-kentico";
import App__imageSlider from "./components/image-slider";
import App__infoBoxes from "./components/info-boxes";
import App__listBox from './components/listbox';
import App__marketing from './components/marketing';
import App__microsite from './components/microsite';
import App__modal from './components/modal';
import App__physicianDetail from './components/physician-detail';
import App__pressRelease from './components/press-release';
import App__search from './components/search';
import App__tabs from "./components/tabs";


// Utilities
import App__linkClasses from "./utilities/link-classes";
import App__formValidation from "./utilities/form-validation";
import App__scrollTo from "./utilities/scroll-to";
import App__sibling from "./utilities/sibling";
import App__tables from "./utilities/tables";
import App__timers from "./utilities/timers";
import App__urlToolkit from "./utilities/url-toolkit";
import App__UUID from "./utilities/uuid";


/*
 * Setup the global App object
 * =========================================================================== */

// Global
App.appHeader = App__appHeader;

// Components
App.accordion = App__accordion;
App.alphasort = App__alphasort;
App.events = App__events;

// App.forms = App__forms; // DEPRECATED
App.formConditionalLogic = App__formConditionalLogic;
App.formPriceDisplay = App__formPriceDisplay;
App.formSubmitKentico = App__formSubmitKentico;

App.imageSlider = App__imageSlider;
App.infoBoxes = App__infoBoxes;
App.listbox = App__listBox;
App.marketing = App__marketing;
App.microsite = App__microsite;
App.modal = App__modal;
App.physicianDetail = App__physicianDetail;
App.pressRelease = App__pressRelease;
App.search = App__search;
App.tabs = App__tabs;


App.utils = {
  linkClasses: App__linkClasses,
  focusTrap: focusTrap,
  formValidation: App__formValidation,
  scrollTo: App__scrollTo,
  sibling: App__sibling,
  urlToolkit: App__urlToolkit,
  tables: App__tables,
  timers: App__timers,
  uuid: App__UUID,
};



// Global App component initialization
App.init = function () {
  // Global
  App.appHeader.init();
  
  // Components
  App.accordion.init();
  App.alphasort.init();
  App.events.init();
  
  // App.forms.init(); // DEPRECATED
  App.formConditionalLogic.init();
  App.formPriceDisplay.init();
  App.formSubmitKentico.init();

  App.imageSlider.init();
  App.infoBoxes.init();
  App.listbox.init();
  App.marketing.init();
  App.microsite.init();
  App.modal.init();
  App.physicianDetail.init();
  App.pressRelease.init();
  App.search.init();
  App.tabs.init();


  // Utilities
  App.utils.linkClasses.init();
  App.utils.tables.makeResponsive();
  App.utils.formValidation.init();
  App.utils.scrollTo.init();

};


window.addEventListener("load", function (event) {
   App.init();

  // Keyboard shortcuts
  document.onkeydown = function (e) {
    e = e || window.event;
    var isEscape = false;
    if ("key" in e) {
      isEscape = e.key === "Escape" || e.key === "Esc";
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
        App.appHeader.closeOverlayPanels();
        App.accordion.handleCloseAll();
        App.microsite.handleClose();
        App.listbox.handleCloseAll();
    }
  };

});
