/**
 * Form Submit - Kentico
 * ---------------------
 * This class handles form submissions from Kentico forms and passes them then an XHR request.
 *
 * It uses a couple utilities and packages that are stored on App.
 *
 * @uses App.Purify : DOMPurify
 * @uses App.utils.scrollTo
 */
const App__formSubmitKentico = {
  debug: false,

  // Default props
  forms: [],
  formSelector: "form[data-submit-kentico], form[data-submit-dotnet]",
  captchaSelector: ".g-recaptcha",
  captchaResponseSelector: ".g-recaptcha-response",
  submittingMessage: "Submitting",
  submitMessage: "Submit",

  // The response properties that get set during the XHR response
  form: null,
  formInstance: null,
  formContainer: null,
  data: null,
  dataDoc: null,
  dataBody: null,
  dataFormInstance: null,

  // Error summary props.
  useErrorSummary: true,
  errorSummary: {
    wrapperClass: "form-error-group",
    fieldErrorMessageClass: "invalid-feedback",
    listWrapperClass: "",
    headerTextAttribute: "data-error-group-title",
    descriptionTextAttribute: "data-error-group-text",
  },

  init: function () {
    try {
      this.forms = Array.from(document.querySelectorAll(this.formSelector));

      if (this.debug) console.log(this.forms);

      if (
        this.forms === undefined ||
        this.forms === null ||
        this.forms.length === 0
      )
        return;

      this.listenToForms();
      this.observeFormContainer();
      this.maybeHandleRecapthca();
      this.maybeAdjustRecaptchaForDotNetForm();
      App.formConditionalLogic.init();
      App.formPriceDisplay.init();
    } catch (e) {
      console.error(e);
    }
  },

  //--------------------- Listeners / Observers ------------------------//

  /**
   * This method handles everything after submit and
   *
   * @uses this.forms
   * @uses this.handleSubmit
   * @uses window.addEventListener
   *
   * @returns void
   */
  listenToForms: function () {
    if(this.debug) console.log('listenToForms');

    this.forms.forEach((formInstance) => {
      this.removeUnwantedAttributes(formInstance);

      // Precautionary reset of the submit button.
      // Have seen an instance where it wasn't setting after the submittion.
      this.resetSubmitButton(formInstance);

      window.addEventListener("submit", function (e) {
        App__formSubmitKentico.handleSubmit(e);
      });
    });
  },

  /**
   * This method sets up an observer that watches the grandparent of the form.
   * If the form and it's parent are removed from the dom, it'll fire and attempt to reinitialize the class.
   *
   * @uses this.forms
   * @uses MutationObserver
   *
   * @returns void
   */
  observeFormContainer: function () {
    if(this.debug) console.log('observeFormContainer');

    this.forms.forEach((formInstance) => {
      const formContainer = formInstance.parentNode;
      const formContainerParent = formContainer.parentNode;

      const observer = new MutationObserver((mutationRecords) => {
        App__formSubmitKentico.handleObserver(mutationRecords);
      });

      observer.observe(formContainerParent, {
        characterData: false,
        subtree: false,
        childList: true,
      });
    });
  },

  //--------------------- Handlers ------------------------//

  /**
   * This will check to see if the form that we're listening to have been removed from the DOM by Kentico
   * If so, we then reinitialize the class
   *
   * @param {MutationRecord} mutationRecords
   *
   * @uses this.forms
   * @uses this.init
   * @uses this.maybeHandleRecapthca
   * @returns void
   */
  handleObserver: function (mutationRecords) {
    if (this.debug) console.log(mutationRecords);

    if (mutationRecords.length === 0) return;

    let hasFormChange = false;

    // Loop-ception
    mutationRecords.forEach((record) => {
      const removedNodes = record.removedNodes;
      removedNodes.forEach((removedNode) => {
        this.forms.forEach((formInstance) => {
          const formInstanceWrapper = formInstance.parentNode;
          if (formInstanceWrapper.isEqualNode(removedNode)) {
            hasFormChange = true;
          }
        });
      });
    });

    if (this.debug) console.log({ hasFormChange });

    if (!hasFormChange) return;

    this.init();
  },

  /**
   * Handles the submit from all forms in the class.
   * @param {Event} e
   *
   * @uses this.form
   * @uses this.handleXHRResponse
   * @uses XMLHttpRequest
   * @returns void
   */
  handleSubmit: function (e) {
    if(this.debug) console.log('handleSubmit');

    e.preventDefault();
    e.stopImmediatePropagation();

    this.form = e.target;

    this.disableSubmitButton(this.form);

    const xhr = new XMLHttpRequest();
    xhr.open(this.form.method, this.form.action);
    xhr.onreadystatechange = () =>
      App__formSubmitKentico.handleXHRResponse(xhr);
    xhr.send(new FormData(this.form));
  },

  /**
   * Handles the response from the HXR and the logic chain for all possible submission scenarios
   * - Redirect
   * - Display Text
   * - Reload Empty Form
   * - Show Validation Errors
   * - Potentially creates Error summary if enabled in config.
   *
   * @param {XMLHttpRequest} xhr
   *
   * @returns void
   */
  handleXHRResponse: function (xhr) {
    // An age old JS hack to rescope this class.
    const $this = App__formSubmitKentico;

    if ($this.debug) console.log("xhr.onreadystatechange");

    const responseSuccess = xhr.readyState == 4 && xhr.status == 200;
    if (!responseSuccess) return;

    $this.formInstance = document.getElementById($this.form.id);
    $this.formContainer = $this.formInstance.parentNode;
    $this.data = xhr.response;

    if ($this.debug) console.log($this.data);

    if ($this.maybeRedirect()) return;

    App.utils.scrollTo.scrollToTop($this.formContainer);

    if ($this.maybeDisplayText()) return;

    if (!$this.maybeParseResponseAsHTML()) return;

    if ($this.maybeDisplayDotNetSuccessMessage()) return;

    if ($this.maybeReloadForm()) return;

    $this.removeUnwantedAttributes($this.formInstance);

    // If we got this far, then we have validation errors.
    $this.maybeHandleErrorSummary();

    // Replace the form that's on the page with the one from the response
    // If there's no form, just put whatever is in the body as a fallback.
    $this.formInstance.innerHTML =
      $this.dataFormInstance !== null
        ? $this.dataFormInstance.innerHTML
        : $this.dataBody.innerHTML;

    $this.focusOnFirstSummaryLinkOrContainer();

    $this.init();
    
    // Re-run so that anything else listening to the form can reattach to the fields. 
    $this.dispatchDOMContentEvent();

  },

  //--------------------- Conditional Handlers ------------------------//

  /**
   * If the form is set to redirect this method will detect it and handle it.
   *
   * @uses this.data
   * @returns boolean
   */
  maybeRedirect: function () {
    if (this.debug) console.log("maybeRedirect");

    const parsedJSON = this.JSONTryParse(this.data);

    if (!parsedJSON || !parsedJSON.redirectTo) return;

    if (this.debug) console.log("maybeRedirect", "... Redirecting ...");

    location.href = parsedJSON.redirectTo;

    return true;
  },

  /**
   * If the form submission is set to display a message after, this method will detect it and handle it.
   *
   * @uses this.data
   * @uses this.formContainer
   * @uses this.focusOnFirstSummaryLinkOrContainer
   *
   * @returns boolean
   */
  maybeDisplayText: function () {
    if (this.debug) console.log("maybeDisplayText");

    if (!this.data.includes("formwidget-submit-text")) return;

    if (this.debug) console.log("maybeDisplayText", true);

    this.formContainer.innerHTML = this.data;

    this.focusOnFirstSummaryLinkOrContainer();
    return true;
  },

  /**
   * If this is a dotnetcore form, then we're checking for [data-success-message] to be in the response.
   *
   * @uses this.dataDoc
   * @uses this.focusOnFirstSummaryLinkOrContainer
   */
  maybeDisplayDotNetSuccessMessage: function () {
    if (this.debug) console.log("maybeDisplayDotNetSuccessMessage");

    const successMessage = this.dataDoc.querySelector("[data-success-message]");

    if (successMessage === undefined || successMessage === null) return;

    if (this.debug) console.log("maybeDisplayDotNetSuccessMessage", true);

    this.formContainer.innerHTML = successMessage.innerHTML;

    this.focusOnFirstSummaryLinkOrContainer();

    return true;
  },

  /**
   * If the form is set to reload itself then this method will detect it and handle it.
   *
   * @uses this.dataDoc
   * @uses this.formInstance
   * @uses this.formContainer
   * @uses this.form
   * @uses this.init
   * @uses this.focusOnFirstSummaryLinkOrContainer
   *
   * @returns boolean
   */
  maybeReloadForm: function () {
    if (this.debug) console.log("maybeReloadForm");

    if (this.formInstance) return;

    if (this.debug) console.log("maybeReloadForm", true);

    const form = this.dataDoc.querySelector("form");
    this.formContainer.innerHTML = this.form?.parentNode?.innerHTML;
    window.removeEventListener("submit", this.handleSubmit);

    this.init();

    this.focusOnFirstSummaryLinkOrContainer();

    return true;
  },

  /**
   * There are several methods that require an parsed html version of the xhr response to do their work.
   * This method handles parsing and setting the response to the class.
   *
   * @sets this.dataDoc
   * @sets this.dataFormInstance
   *
   * @uses this.dataDoc
   *
   * @returns boolean
   */
  maybeParseResponseAsHTML: function () {
    if (this.debug) console.log("maybeParseResponseAsHTML");

    const parser = new DOMParser();
    this.dataDoc = parser.parseFromString(this.data, "text/html");
    this.dataBody = this.dataDoc.querySelector("body");
    this.dataFormInstance = this.dataDoc.getElementById(this.form.id);

    if (this.dataDoc === undefined || this.dataDoc === null) {
      console.error(
        "No response to parse from the server, the form will silently fail."
      );
      return;
    }

    if (this.debug) console.log("maybeParseResponseAsHTML", true);

    return true;
  },

  /**
   * This method will create an error summary if the configuration is set to do so.
   *
   * @uses this.useErrorSummary
   * @uses this.maybeRemoveExistingErrors
   * @uses this.createErrorSummary
   *
   * @returns void
   */
  maybeHandleErrorSummary: function () {
    if (this.debug) console.log("maybeHandleErrorSummary");

    if (!this.useErrorSummary) return;

    if (this.debug) console.log("maybeHandleErrorSummary", true);

    this.maybeRemoveExistingErrors();

    this.createErrorSummary();
  },

  /**
   * This method will handle reinitializing recapthca on init.
   *
   * @uses this.captchaClass
   *
   * @returns void
   */
  maybeHandleRecapthca: function () {
    if (this.debug) console.log("maybeHandleRecapthca");

    let captchas = Array.from(document.querySelectorAll(this.captchaSelector));

    if (captchas.length === 0) return;

    if (this.debug) console.log("maybeHandleRecapthca", true);

    captchas.forEach((captcha) => {
      const hasIframe = captcha.querySelector("iframe");

      if (this.debug) console.log({ hasIframe });
      // Prevents captcha from throwing a "reCAPTCHA has already been rendered in this element" error.
      if (
        (hasIframe === undefined || hasIframe === null) &&
        grecaptcha !== undefined
      ) {
        grecaptcha.render(captcha, {
          sitekey: captcha.dataset.sitekey,
        });
      }
    });
  },

  /**
   * This method will remove any existing error summaries that are on the document before setting the new summary in its place.
   *
   * @uses this.errorSummary.wrapperClass
   *
   * @returns void
   */
  maybeRemoveExistingErrors: function () {
    if (this.debug) console.log("maybeRemoveExistingErrors");

    // Using an array jic there are more than one on the page for some reason.
    const existingErrorDiv = Array.from(
      this.formContainer.querySelectorAll(`.${this.errorSummary.wrapperClass}`)
    );

    if (existingErrorDiv.length === 0) return;

    if (this.debug) console.log("maybeRemoveExistingErrors", true);

    existingErrorDiv.forEach((div) => div.remove());

    return;
  },

  maybeAdjustRecaptchaForDotNetForm: function () {
    if (this.debug) console.log("maybeAdjustRecaptchaForDotNetForm");
    this.forms.forEach((formInstance) => {
      if (formInstance.hasAttribute("data-submit-dotnet")) {
        const recaptchaResponse = formInstance.querySelector(
          this.captchaResponseSelector
        );

        if (this.debug) console.log({ recaptchaResponse });

        if (recaptchaResponse !== undefined && recaptchaResponse !== null) {
          recaptchaResponse.setAttribute("name", "CaptchaResponse");
        }
      }
    });
  },

  //--------------------- UTILITIES ------------------------//

  /**
   * This will attempt to parse the XHR response as JSON
   * - If it passes, will return JSON
   * - If it fails, will return false
   * @param {XMLHttpRequest} data
   *
   * @returns JSONObject | false
   */
  JSONTryParse: function (data) {
    try {
      const output = JSON.parse(data);
      if (this.debug) console.log("JSONTryParse", true);
      return output;
    } catch (e) {
      if (this.debug) console.log("JSONTryParse", false);
      return false;
    }
  },

  /**
   * Removes attributes from the form the Kentico places there for their handlers.
   * It also removes anything in the form with the class of js-remove
   *
   * @param {HTMLFormElement} formInstance
   *
   * @returns void
   */
  removeUnwantedAttributes: function (formInstance) {
    if (this.debug) console.log("removeUnwantedAttributes");

    formInstance.removeAttribute("onsubmit");

    // This is needed for conditional logic on forms
    // formInstance.removeAttribute("data-ktc-ajax-update");

    const jsRemoves = Array.from(formInstance.querySelectorAll(".js-remove"));

    if (jsRemoves.length > 0) {
      jsRemoves.forEach((elem) => elem.remove());
    }

    return;
  },

  /**
   * Builds the error summary and adds it to the top of the form container
   *
   * @uses this.dataFormInstance
   * @uses this.errorSummary : The whole object.
   *
   * @returns void | HTMLDivElement
   */
  createErrorSummary: function () {
    if (this.debug) console.log("createErrorSummary");

    if (this.dataFormInstance === undefined || this.dataFormInstance === null)
      return;

    const errors = Array.from(
      this.dataFormInstance.querySelectorAll(
        `.${this.errorSummary.fieldErrorMessageClass}`
      )
    );

    if (errors.length === 0) {
      if (this.debug) console.log("createErrorSummary", false);
      return;
    }

    if (this.debug) console.log("createErrorSummary", true);

    // The wrapper of the error summary;
    const errorSummaryWrapper = document.createElement("div");
    errorSummaryWrapper.classList.add(this.errorSummary.wrapperClass);
    errorSummaryWrapper.setAttribute("tabindex", "0");
    errorSummaryWrapper.setAttribute("aria-atomic", "true");
    errorSummaryWrapper.setAttribute("aria-live", "assertive");

    // The heading of the error summary
    const headingText = this.dataFormInstance.getAttribute(
      this.errorSummary.headerTextAttribute
    );

    if (
      headingText !== undefined &&
      headingText !== null &&
      headingText !== ""
    ) {
      const heading = document.createElement("h2");
      heading.innerText = App.Purify.sanitize(headingText);
      errorSummaryWrapper.appendChild(heading);
    }

    // The desciption of the error summary
    const descriptionText = this.dataFormInstance.getAttribute(
      this.errorSummary.descriptionTextAttribute
    );

    if (
      descriptionText !== undefined &&
      descriptionText !== null &&
      descriptionText !== ""
    ) {
      const errorSummaryText = document.createElement("p");
      errorSummaryText.innerText = App.Purify.sanitize(descriptionText);
      errorSummaryWrapper.appendChild(errorSummaryText);
    }

    // A wrapper for the order list... not sure why this is here?
    const listDiv = document.createElement("div");
    if (this.errorSummary.listWrapperClass) {
      listDiv.classList.add(this.errorSummary.listWrapperClass);
    }
    errorSummaryWrapper.appendChild(listDiv);

    // The list of errors.
    const orderedList = document.createElement("ol");
    listDiv.appendChild(orderedList);

    // Add all of the errors to the list.
    errors.forEach((error) => {
      const listItem = document.createElement("li");
      const inputFieldId = error.id.replace("error-", "");
      const anchor = document.createElement("a");

      anchor.setAttribute("href", `#${inputFieldId}`);
      anchor.innerText = App.Purify.sanitize(error.innerText.trim());

      listItem.appendChild(anchor);
      orderedList.appendChild(listItem);
    });

    // Add the error summary
    this.dataFormInstance.insertBefore(
      errorSummaryWrapper,
      this.dataFormInstance.firstChild
    );

    return errorSummaryWrapper;
  },

  /**
   * After the form is submitted, this method will set focus to either the first link in the error summary or the formContainer
   *
   * @uses this.formContainer
   * @uses this.errorSummary.wrapperClass
   *
   * @returns void
   */
  focusOnFirstSummaryLinkOrContainer: function () {
    if (this.debug) console.log("focusOnFirstSummaryLink");
    const firstFocusableLink = document.querySelector(
      `.${this.errorSummary.wrapperClass} a`
    );

    if (firstFocusableLink === undefined || firstFocusableLink === null) {
      this.formContainer.focus({ preventScroll: true });
      return;
    }

    firstFocusableLink.focus({ preventScroll: true });

    return;
  },

  /**
   * This method forces a DOMContentLoaded event to be dipatched.  This is so that the Kentico listeners reinitialize after the form has been placed back into the DOM.
   *
   * @uses Event
   * @uses window.document.dispatchEvent
   *
   * @returns void
   */
  dispatchDOMContentEvent: function () {
    if (this.debug) console.log("dispatchDOMContentEvent");
    window.document.dispatchEvent(
      new Event("DOMContentLoaded", {
        bubbles: true,
      })
    );

    return;
  },

  /**
   * Sets the submit button to disabled and lets the user know that the form is submitting.
   *
   * @uses this.form
   * @uses App.Purify
   * @returns void
   */
  disableSubmitButton: function (formInstance) {
    if(this.debug) console.log('disableSubmitButton');
    const submitButton = formInstance.querySelector("[type=submit]");

    if (submitButton === undefined || submitButton === null) return;

    submitButton.value = App.Purify.sanitize(this.submittingMessage);
    submitButton.disabled = true;
  },

  /**
   * Sets the submit button to disabled and lets the user know that the form is submitting.
   *
   * @uses this.form
   * @uses App.Purify
   * @returns void
   */
  resetSubmitButton: function (formInstance) {
    if(this.debug) console.log('resetSubmitButton');
    const submitButton = formInstance.querySelector("[type=submit]");

    if (submitButton === undefined || submitButton === null) return;

    submitButton.value = App.Purify.sanitize(this.submitMessage);
    submitButton.disabled = false;
  },
};

export default App__formSubmitKentico;
