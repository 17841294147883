const App__infoBoxes = {
  modal: null,
  modalBody: null,
  data: null,
  allTriggers: [],
  components: null,
  maxListboxesShown: 4,

  init: function () {
    try {
      this.modal = document.getElementById("infoBoxModal");

      if (this.modal === undefined || this.modal === null || !this.modal)
        return;

      this.modalBody = this.modal.querySelector("[data-hook=physician_body]");

      // Precautionary reset
      this.handleReset();

      this.listenToModalOpen();

      this.listenToModalClose();

      this.allTriggers = document.querySelectorAll(
        "[data-hook=itemBoxes__revealAll]"
      );

      this.listenToAllTrigger();

      this.components = document.querySelectorAll("[data-hook=infoBoxes]");

      if (
        this.components === undefined ||
        this.components === null ||
        this.components.length === 0
      )
        return;

      this.handleListboxRandomization();
    } catch (e) {
      console.error(e);
    }
  },

  /***
   *
   * LISTENERS
   *
   */

  listenToModalOpen: async function () {
    this.modal.addEventListener("show.bs.modal", async (e) => {
      this.handleLoading(true);

      this.setData(e);

      if (!this.data) {
        this.layoutNoResults();
        this.handleLoading(false);
        return;
      }

      this.layoutModal();
      this.handleLoading(false);
    });
  },

  listenToModalClose: function () {
    this.modal.addEventListener("hide.bs.modal", async (e) => {
      this.handleReset();
    });
  },

  listenToAllTrigger: function () {
    if (!this.allTriggers.length) return;

    this.allTriggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        const infoBoxes = trigger.closest("[data-hook=infoBoxes]");

        const list = infoBoxes.querySelector("[data-hook=infoBoxes__list]");
        this.handleListboxResorting(list);

        const items = list.querySelectorAll("li");

        if (infoBoxes === undefined || !items.length) return;

        items.forEach((item) => {
          item.classList.remove("d-none");
        });

        trigger.remove();

        list.setAttribute("tabindex", 0);
        list.focus();
      });
    });
  },

  /***
   *
   * SETTERS
   *
   */

  setData: function (e) {
    const button = e.relatedTarget;

    if (button === undefined || button.dataset.nodeid === undefined) {
      this.data = false;
      return;
    }

    this.data = { ...this.data, ...button.dataset };

    this.handleParseJSONArrays("positions", button.dataset.positions);

    this.handleParseRating();

    this.data.appointmentLink = this.modalBody.dataset.appointmentLink;

    return;
  },

  /***
   *
   * HANDLERS
   *
   */

  handleLoading: function (currentState = true) {
    this.modal.setAttribute("data-loading", currentState);
  },

  handleReset: function () {
    // RESET IT ALL
    this.data = {
      positions: [],
      name: "",
      url: "",
      image: "",
      rating: false,
      appointment: true,
      appointmentLink: "#",
    };

    this.modalBody.innerHTML = "";
  },

  handleParseJSONArrays: function (attribute, value) {
    if (value === undefined || value === null) return;

    this.data[attribute] = JSON.parse(value);
  },

  handleParseRating: function () {
    if (this.data.rating === "false") {
      this.data.rating = false;
      return;
    }

    this.data.rating = {
      float: parseFloat(this.data.rating),
      number: Math.floor(this.data.rating),
      decimal: Number((this.data.rating % 1).toFixed(1).substring(2)),
    };
  },

  handleListboxRandomization: function () {
    this.components.forEach((component) => {
      const list = component.querySelector('[data-hook="infoBoxes__list"]');

      let listItems = Array.from(
        component.querySelectorAll('ul[data-hook="infoBoxes__list"] li')
      );

      // Assign them an index based on the order they're already in order.
      listItems.forEach((item, i) => item.setAttribute("data-index", i));

      // prettier-ignore
      const randomInts = App.utils.uuid.randomArrayOfNumbersBetween(0, listItems.length, this.maxListboxesShown);

      if (randomInts === undefined || randomInts.length === 0) return;

      // Move the selected randoms to the top of the list in the random order (reveresed due to prepend).
      randomInts.forEach((i) => {
        const item = listItems[i];
        if (item !== undefined) {
          item.classList.remove("d-none");
          item.remove();
          list.prepend(item);
        }
      });
    });
  },

  handleListboxResorting: function (list) {
    let items = Array.from(list.querySelectorAll("li"));
    // Sort the items based on their load index
    items = items.sort((a, b) => a.dataset.index - b.dataset.index);
    items.forEach((item) => {
      item.remove();
      list.append(item);
    });
  },

  /**
   *
   * LAYOUT
   *
   */

  layoutModal: function () {
    const body = ` <div class="row gy-3">
        <div class="col-12 col-lg-4">
          <img 
            src="${this.data.image}" 
            alt="${this.data.name}" 
            loading="eager" />
        </div> 
        <div class="col-12 col-lg-8">
          <h3><a href="${this.data.url}">${this.data.name}</a></h3>
          <p>${this.data.bio}</p>
          ${this.layoutLists(this.data.positions, "positions")}
          <hr class="hr-orange--500">
          <a href="${this.data.url}">Visit Full Profile</a>
          <hr />
          <div class="row gy-3 align-items-center">
            ${this.layoutPressGaney()}
            ${this.layoutAppointment()}
          </div>
      </div>
    </div>`;

    this.modalBody.innerHTML = App.Purify.sanitize(body);
  },

  layoutLists: function (data, label = "") {
    let response = "";

    if (data === undefined || data.length === 0) return response;

    response = `<ul role="list" aria-label="${label}">`;

    data.forEach((item) => {
      response += `<li>`;
      if (item.Link !== undefined && item.Link !== "") {
        response += `<a href="${item.Link}">${item.Text}</a>`;
      } else {
        response += item.Text;
      }
      response += `</li>`;
    });

    response += "</ul>";

    return response;
  },

  layoutPressGaney: function () {
    let response = "";

    if (!this.data.rating) return response;

    response += `
        <div class="col-12 col-md-6">
          <h4>Patient Rating</h4>
          <div class="info-boxes__rating">
            ${this.layoutStars()}
          </div>
        <p class="text-muted">
          <span itemprop="ratingValue">
          ${this.data.rating.float}
          </span> out of <span itemprop="bestRating">5</span>
        </p> 
      </div>`;

    return response;
  },

  layoutStars: function () {
    let response = "";

    /**
     * 
     * Example shows a 3.5 rating. 
     *  
      <img src="/images/stars/1_0.svg" alt="" role="presentation" />
      <img src="/images/stars/1_0.svg" alt="" role="presentation" />
      <img src="/images/stars/1_0.svg" alt="" role="presentation" />
      <img src="/images/stars/0_5.svg" alt="" role="presentation" />
      <img src="/images/stars/0_0.svg" alt="" role="presentation" />
      */

    for (let i = 0; i < 5; i++) {
      if (i < this.data.rating.number) {
        response += `<img src="/images/stars/1_0.svg" alt="" role="presentation" />`;
      }

      if (i === this.data.rating.number) {
        response += `<img src="/images/stars/0_${this.data.rating.decimal}.svg" alt="" role="presentation" />`;
      }

      if (i > this.data.rating.number) {
        response += `<img src="/images/stars/0_0.svg" alt="" role="presentation" />`;
      }
    }

    return response;
  },

  layoutAppointment: function () {
    let response = "";

    if (!this.data.appointment) return response;

    const className = this.data.rating !== false ? "col-md-6" : "";

    response += `<div class="col-12 text-md-end ${className}">
        <a href="${this.data.appointmentLink}" class="btn btn-sm btn-blue--700">
          Make an Appointment
        </a>
      </div>`;

    return response;
  },

  layoutNoResults: function () {
    const body = `<h2>An error has occured, please try again.</h2>`;

    this.modalBody.innerHTML = App.Purify.sanitize(body);
  },
};

export default App__infoBoxes;
