const App__marketing = {
  header: null,
  scrolled: false,

  main: null,
  mainSet: false,

  doctors: null,
  testimonials: null,

  floatingCTA: null,
  floatingDrawer: null,
  floatingTriggers: [],
  init: function () {
    try {
      this.header = document.querySelector("[data-hook=marketingHeader]");

      if (this.header === undefined || this.header === null) return;

      this.main = document.querySelector("[data-hook=marketingMain]");

      this.doctors = document.querySelector(
        "[data-hook=marketingDoctors] .swiper"
      );

      this.testimonials = document.querySelector(
        "[data-hook=marketingTestimonials] .swiper"
      );

      this.floatingCTA = document.querySelector(
        "[data-hook=marketingFloatingCta]"
      );
      this.floatingDrawer = document.querySelector(
        "[data-hook=marketingFloatingCta__drawer]"
      );
      this.floatingTriggers = document.querySelectorAll(
        "[data-hook=marketingFloatingCta__trigger]"
      );

      this.handleMainOffset();

      this.listenToScroll();

      this.listenToResize();

      this.handleDoctors();

      this.handleTestimonials();

      this.listenToFloatingTriggers();
    } catch (e) {
      console.error(e);
    }
  },

  /**
   *
   * LISTENERS
   *
   */

  listenToScroll: function () {
    window.addEventListener("scroll", (e) => {
      this.handleStickyHeader(e, 500);
      this.handleMainOffset();
    });
  },

  listenToResize: function () {
    window.addEventListener("resize", (e) => {
      this.handleMainOffset(true);
    });
  },

  listenToFloatingTriggers: function () {
    if (
      this.floatingTriggers === undefined ||
      this.floatingTriggers.length === 0
    )
      return;

    this.floatingTriggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        this.handleToggleDrawer();
      });
    });
  },

  /**
   *
   * HANDLERS
   *
   */
  handleStickyHeader: function (e, distance) {
    if (window.pageYOffset > distance) {
      this.header.classList.add("is-scrolled");
      this.scrolled = true;
    } else {
      this.header.classList.remove("is-scrolled");
      this.scrolled = false;
    }
  },

  handleMainOffset: function (override = false) {
    if ((this.scrolled || this.mainSet) && !override) return;

    const headerHeight = this.header.offsetHeight;

    this.main.style.paddingTop = `${headerHeight}px`;

    this.mainSet = true;
  },

  handleDoctors: function () {
    if (this.doctors === null || this.doctors === undefined) return;

    const initSlider = new App.Swiper(this.doctors, {
      slidesPerView: 1,
      loop: true,
      disableOnInteraction: false,
      centeredSlides: true,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: this.doctors.querySelector(".swiper-pagination"),
        type: "fraction",
      },
      navigation: {
        nextEl: this.doctors.querySelector(".swiper-button-next"),
        prevEl: this.doctors.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
    });
  },

  handleTestimonials: function () {
    if (this.testimonials === null || this.testimonials === undefined) return;
    const initSlider = new App.Swiper(this.testimonials, {
      slidesPerView: 1,
      loop: true,
      disableOnInteraction: false,
      autoHeight: true,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: this.testimonials.querySelector(".swiper-pagination"),
        type: "fraction",
      },
      navigation: {
        nextEl: this.testimonials.querySelector(".swiper-button-next"),
        prevEl: this.testimonials.querySelector(".swiper-button-prev"),
      },
    });
  },

  handleToggleDrawer: function () {
    if (this.floatingDrawer === undefined) return;

    if (this.floatingDrawer.getAttribute("aria-hidden") === "true") {
      this.floatingCTA.classList.add("is-open");
      this.floatingDrawer.setAttribute("aria-hidden", false);
      this.floatingTriggers.forEach((trigger) => {
        trigger.setAttribute("aria-expanded", true);
      });

      return;
    }

    this.floatingCTA.classList.remove("is-open");
    this.floatingDrawer.setAttribute("aria-hidden", true);
    this.floatingTriggers.forEach((trigger) => {
      trigger.setAttribute("aria-expanded", false);
    });
  },
};

export default App__marketing;
