const App__events = {
  oneTimeEvents: [],
  filters: [],
  events: [],

  activeGroup: null,
  running: false,

  unsortedGroups: new Map(),
  groups: new Map(),

  init: function () {
    try {
      this.oneTimeEvents = document.querySelectorAll(
        "[data-hook=eventOneTime]"
      );

      if (this.oneTimeEvents === undefined || this.oneTimeEvents.length === 0)
        return;

      this.handleUniqueIds();

      this.filters = document.querySelectorAll(
        "[data-hook=eventOneTime__filter] input"
      );

      if (this.filters === undefined || this.filters.length === 0) return;

      this.listenToFilters();
    } catch (e) {
      console.error(e);
    }
  },

  /**
   *
   * LISTENERS
   *
   */

  listenToFilters: function () {
    this.filters.forEach((filter) => {
      filter.addEventListener("change", (e) => {
        if (this.running) return;
        this.handleFilterGroups(e.target);
      });
    });
  },

  /**
   *
   * HANDLERS
   *
   */
  handleUniqueIds: function () {
    // Add custom id to all elements that will be triggered.
    this.oneTimeEvents.forEach((eventLists) => {
      const id = App.utils.uuid.generate("events");

      const inputs = eventLists.querySelectorAll(
        "[data-hook=eventOneTime__filter] input"
      );

      const groups = eventLists.querySelectorAll(
        "[data-hook=eventOneTime__groups]"
      );

      eventLists.id = id;

      inputs.forEach((input) => {
        const value = input.value;
        const label = App.utils.sibling.getNext(input);
        input.id = `${id}_${value}`;
        input.setAttribute("data-target", `${id}_groups`);
        label.setAttribute("for", `${id}_${value}`);
      });

      groups.forEach((group) => {
        group.id = `${id}_groups`;
      });
    });
  },

  handleFilterGroups: function (element) {
    this.run(element);

    if (element.value === "location") {
      this.handleOrderByLocation();
      return;
    }

    this.handleOrderByDate();
    return;
  },

  handleOrderByLocation: function () {
    this.events.forEach((e) => {
      const location = e.dataset.location;

      this.constructUnsortedMapByKey(location, e);
    });

    this.groups = new Map([...this.unsortedGroups.entries()].sort());

    this.orderEventGroupsByDateTime();

    this.renderEvents();
  },

  handleOrderByDate: function () {
    this.events.forEach((e) => {
      const date = e.dataset.date;
      const datetime = new Date(date);
      const month = datetime.toLocaleString(undefined, {
        month: "long",
        year: "numeric",
      });

      this.constructUnsortedMapByKey(month, e);
    });

    // Sort by the month / year
    this.groups = new Map(
      [...this.unsortedGroups.entries()].sort(
        (a, b) => new Date(a[0]) - new Date(b[0])
      )
    );

    const renameHeadingToMonth = new Map();
    this.groups.forEach((value, key) => {
      const month = new Date(key);

      renameHeadingToMonth.set(
        month.toLocaleString(undefined, { month: "long" }),
        value
      );
    });

    this.groups = renameHeadingToMonth;

    this.orderEventGroupsByDateTime();

    this.renderEvents();
  },

  /**
   *
   * UTILITIES
   *
   */

  orderEventGroupsByDateTime: function () {
    this.groups.forEach((value, key) => {
      const sortedValue = value.sort(
        (a, b) => new Date(a.dataset.date) - new Date(b.dataset.date)
      );

      this.groups.set(key, sortedValue);
    });
  },

  constructUnsortedMapByKey: function (key, e) {
    if (!key || !e) return;

    if (
      this.unsortedGroups.size === 0 ||
      this.unsortedGroups.has(key) === undefined ||
      !this.unsortedGroups.has(key)
    ) {
      this.unsortedGroups.set(key, new Array(e));
    } else {
      const groupValue = this.unsortedGroups.get(key);
      groupValue.push(e);
      this.unsortedGroups.set(key, groupValue);
    }
  },

  renderEvents: function () {
    if (
      this.groups.size === 0 &&
      this.activeGroup === undefined &&
      this.activeGroup === null
    )
      return;

    this.activeGroup.innerHTML = "";

    this.groups.forEach((value, key) => {
      const group = document.createElement("div");
      group.classList.add("event-one-time__group");

      const heading = document.createElement("h3");
      heading.innerHTML = App.Purify.sanitize(key);
      group.appendChild(heading);

      const hr = document.createElement("hr");
      hr.classList.add("hr-orange--500");
      group.appendChild(hr);

      value.forEach((e) => {
        group.appendChild(e);
      });

      this.activeGroup.appendChild(group);
    });

    this.reset();
  },

  run: function (element) {
    if (!element || element === undefined) return;

    this.running = true;

    this.events = document.querySelectorAll(
      `#${element.dataset.target} .event`
    );
    this.activeGroup = document.getElementById(element.dataset.target);
  },

  reset: function () {
    this.running = false;

    this.groups.clear();

    this.unsortedGroups.clear();
  },
};

export default App__events;
