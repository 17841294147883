const App__scrollTo = {
  scrollTo: undefined,

  init: function () {
    const params = App.utils.urlToolkit.parseQueryString();

    this.scrollTo = params.scrollto;

    if (this.scrollTo === undefined || this.scrollTo === null) return;

    this.scrollToSection();
  },

  scrollToSection: function () {
    const section = document.getElementById(this.scrollTo);

    if (section === undefined || section === null) return;

    window.scrollTo({
      top: section.offsetTop,
      behavoir: "smooth",
    });
    section.focus({focusVisible: true});
  },

  scrollToTop: function (element, offsetElement = null) {
    const elementTop = element.getBoundingClientRect().top;

    const windowScroll = window.scrollY;

    let offsetElementHeight = 0;
    if (offsetElement !== undefined && offsetElement !== null) {
      offsetElementHeight = offsetElement.getBoundingClientRect().height;
    }

    const scrollToField = Math.floor(
      elementTop + windowScroll + offsetElementHeight
    );

    window.scrollTo({
      top: scrollToField,
    });
  },
};

export default App__scrollTo;
