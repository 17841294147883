const App__search = {
  debug: false,

  elements: [],
  fields: null,
  form: null,
  submitUrl: null,
  valid: true,
  values: {},

  init: function () {

    try {
      this.elements = document.querySelectorAll("[data-hook=keywordSearch]");

      if (this.elements === undefined || this.elements.length === 0) return;

      this.listenToSubmit();

      this.listenToChange();

      this.handleDefaultValues();
    } catch (e) {
      console.error(e);
    }
  },

  /***
   *
   * LISTENERS
   *
   */
  listenToSubmit: function () {
    this.elements.forEach((form) => {
      form.addEventListener("submit", (e) => {
        e.preventDefault();
        this.form = form;

        this.fields = this.form.querySelectorAll("[data-param]");

        if (
          this.fields === null ||
          this.fields === undefined ||
          this.fields.length === 0
        )
          return;

        this.getFormValues();
        this.checkValidity();

        if (!this.valid) return;

        this.constructUrl();

        this.handleSubmit();
      });
    });
  },

  listenToChange: function () {
    this.elements.forEach((form) => {
      form.addEventListener("change", (e) => {
        if (e.target.type !== "checkbox") return;

        if (e.target.value === "all") {
          this.handleSelectAll(form, e.target);
        } else {
          this.handleUnselectAll(form, e.target);
        }
      });
    });
  },

  /**
   *
   * HANDLERS
   *
   */

  handleDefaultValues: function () {
    const query = App.utils.urlToolkit.parseQueryString();
    this.elements.forEach((form) => {
      this.fields = form.querySelectorAll("[data-param]");
    });

    if (
      this.fields === null ||
      this.fields === undefined ||
      this.fields.length === 0
    )
      return;

    this.fields.forEach((field) => {
      const queryValue = query[field.dataset.param];
      const hasFieldValue =
        field.value !== undefined && field.value !== "" && field.value !== null && field.value !== "both";
      const hasQueryValue =
        queryValue !== undefined && queryValue !== "" && queryValue !== null;
      if (!hasFieldValue && hasQueryValue) {
        field.value = App.Purify.sanitize(queryValue);
      }
    });
  },

  getFormValues: function () {
    this.fields.forEach((field) => {
      const param = field.dataset.param;

      if (field.type === "checkbox") {
        this.handleCheckbox(field);
      } else {
        this.values[param] = field.value;
      }
    });
  },

  handleCheckbox: function (input) {
    const param = input.dataset.param;
    const value = input.value;

    let paramValue = this.values[param] !== undefined ? this.values[param] : [];

    if (input.checked && !paramValue.includes(value)) {
      paramValue.push(value);
    }

    if (!input.checked && paramValue.includes(value)) {
      const index = paramValue.indexOf(value);
      if (index > -1) {
        paramValue.splice(index, 1);
      }
    }

    this.values[param] = paramValue;
  },

  checkValidity: function (form) {
    let invalid = 0;
    this.fields.forEach((field) => {
      if (!field.validity.valid) {
        invalid++;
      }
    });

    this.valid = invalid === 0 ? true : false;
  },

  constructUrl: function () {
    const searchUrl = this.form.dataset.searchUrl;
    const extraParams = this.form.dataset.extraParams;

    let submitUrl = searchUrl;
    let index = 0;
    let sep = "?";

    for (let [key, value] of Object.entries(this.values)) {
      let paramValue = Array.isArray(value) ? value.join(",") : value;

      if (
        paramValue !== undefined &&
        paramValue !== null &&
        paramValue !== ""
      ) {
        paramValue = App.Purify.sanitize(paramValue);
        submitUrl += `${sep}${key}=${paramValue}`;
        sep = "&";
      }

      index++;
    }

    if (
      extraParams !== undefined &&
      extraParams !== null &&
      extraParams !== ""
    ) {
      submitUrl += `${sep}${extraParams}`;
    }
    this.submitUrl = submitUrl;
  },

  handleSubmit: function (form) {
    if (this.submitUrl === undefined || this.submitUrl === null) return;
    window.location.href = this.submitUrl;
  },

  /**
   *
   * Select All
   *
   */

  handleUnselectAll: function (form, input) {
    const selectAll = form.querySelector(
      `[data-param=${input.dataset.param}][value=all]`
    );

    if (input.checked && selectAll !== undefined) {
      selectAll.checked = false;
    }
  },

  handleSelectAll: function (form, input) {
    const siblingInputs = form.querySelectorAll(
      `[data-param=${input.dataset.param}]:not([value=all])`
    );

    if (input.checked && siblingInputs.length !== 0) {
      siblingInputs.forEach((sibling) => {
        sibling.checked = false;
      });
    }
  },
};

export default App__search;
